export default {
  data() {
    return {
      addProperty: {
        pfor: "",
        ptype: "",
        possession: "",
        pg_for: "",
        occupency: "",
        p_time: "",
        P_Age: "",
        property_type_id: "",
        locality: "",
        address: "",
        city: "",
        district: "",
        state: "",
        price_negotiable: "",
        description: "",
        price: "",
        super_built_area: "",
        built_area: "",
        carpet_area: "",
        hectare: "",
        plot_area: "",
        total_no_floor: "",
        floor_no: "",
        bedroom: "",
        bathroom: "",
        balcony: "",
        facing: "",
        p_width: "",
        p_length: "",
        road_width: "",
        open_side: "",
        compound: "",
        cafeteria: "",
        washroom: "",
        road_facing: "",
        corner_property: "",
        propertyImg: "",
        //amenity: [],
        maintenance: "",
        Available_from: new Date().toISOString().substr(0, 10),
      },

      datepicker: false,
      ptime: [
        "within a 3 Months",
        "within a 6 month",
        "within 1 year",
        "within a 2 year",
      ],
      age: [
        "upto 1 year",
        "1-5 year",
        "5-10 year",
        "10-15 year",
        "15-20 year",
        "20-25 year",
        "25-30 year",
        "30+ year",
      ],

      sides: ["1", "2", "3", "4"],
      itemlist: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "10+"],
      balconylist: ["0", "1", "2", "3", "4", "5", "5+"],
      facinglist: ["North", "East", "West", "South"],

      pforRules: [(v) => !!v || "Property For is required"],

      ptypeRules: [(v) => !!v || "Property Type is required"],

      possessionRules: [(v) => !!v || "Possession is required"],

      pgforRules: [(v) => !!v || "Accomodation For is required"],

      occupencyRules: [(v) => !!v || "Occupency is required"],

      ptimeRules: [(v) => !!v || "Available From is required"],

      P_AgeRules: [(v) => !!v || "Property Age is required"],
      PickerRules: [(v) => !!v || "available from is required"],
      sbuiltupRules: [
        (v) => !!v || "Super Builtup area is required",
        (v) => !isNaN(parseInt(v)) || "Super Builtup area must be digit.",
      ],
      builtupRules: [
        (v) => !!v || "Builtup area is required",
        (v) => !isNaN(parseInt(v)) || "Builtup area must be digit.",
      ],
      carpetRules: [
        (v) => !!v || "carpet area is required",
        (v) => !isNaN(parseInt(v)) || "carpet area must be digit.",
      ],
      floorRules: [(v) => !!v || "Floor Number is required"],
      hareaRules: [
        (v) => !!v || "Hectre area is required",
        (v) => !isNaN(parseInt(v)) || "Hectre area must be digit.",
      ],

      plotRules: [
        (v) => !!v || "Plot area is required",
        (v) => !isNaN(parseInt(v)) || "Plot area must be digit.",
      ],

      totalfloorRules: [(v) => !!v || "total no. of floor is required"],
      bedroomRules: [(v) => !!v || "bedroom is required"],
      bathroomRules: [(v) => !!v || "bathroom is required"],
      balconyRules: [(v) => !!v || "balcony is required"],
      pwidthRules: [
        (v) => !!v || "Plot width is required",
        (v) => !isNaN(parseInt(v)) || "Plot width must be digit.",
      ],
      plengthRules: [
        (v) => !!v || "Plot length is required",
        (v) => !isNaN(parseInt(v)) || "Plot length must be digit.",
      ],
      roadwidthRules: [
        (v) => !!v || "Road width is required",
        (v) => !isNaN(parseInt(v)) || "Road width must be digit.",
      ],
      facingRules: [(v) => !!v || "facing is required"],
      opensideRules: [(v) => !!v || "Number of open side is required"],
      washroomRules: [(v) => !!v || "washroom is required"],
      cafeteriaRules: [(v) => !!v || "cafeteria is required"],
      mainRoadFacingRules: [(v) => !!v || "Main Road Facing is required"],
      cornerPropertyRules: [(v) => !!v || "Corner Property is required"],
      priceRules: [
        (v) => !!v || "Price is required",
        (v) => !isNaN(parseInt(v)) || "Price must be digit.",
      ],
      descriptionRules: [(v) => !!v || "description is required"],
      propertyRules: [(v) => !!v || "property is required"],
      addressRules: [(v) => !!v || "Address is required"],
      cityRules: [(v) => !!v || "City is required"],
      localityRules: [(v) => !!v || "Locality or Area is required"],
      districtRules: [(v) => !!v || "District is required"],
      stateRules: [(v) => !!v || "State is required"],
      maintenanceRules: [(v) => !!v || "Maintenance is required"],
    };
  },
  methods: {
    /*uploadImage(e){ 
                      this.propertyImage = e;                    
                  let reader = new FileReader();              
                      reader.onload = e => {                
                      this.propPicture = e.target.result;                  
                      }
                      reader.readAsDataURL(e);
                  },*/

    clearAllField() {
      (this.propPicture = ""), (this.addProperty.property_type_id = "");
      this.addProperty.address = "";
      this.addProperty.city = "";
      this.addProperty.district = "";
      this.addProperty.state = "";
      this.addProperty.price_negotiable = "";
      this.addProperty.description = "";
      this.addProperty.price = "";
      this.addProperty.sbuiltup;
      this.addProperty.builtup = "";
      this.addProperty.carpet = "";
      this.addProperty.harea = "";
      this.addProperty.plotarea = "";
      this.addProperty.totalfloor = "";
      this.addProperty.floorno = "";
      this.addProperty.bedroom = "";
      this.addProperty.bathroom = "";
      this.addProperty.balcony = "";
      this.addProperty.facing = "";
      this.addProperty.pwidth = "";
      this.addProperty.plength = "";
      this.addProperty.roadwidth = "";
      this.addProperty.open_side = "";
      this.addProperty.compound = "";
      this.addProperty.cafeteria = "";
      this.addProperty.washroom = "";
      this.addProperty.road_facing = "";
      this.addProperty.corner_property = "";
      //this.addProperty.propertyImg="";
      this.addProperty.amenity = "";
      this.addProperty.maintenance = "";
      this.addProperty.pfor = "";
      this.addProperty.ptype = "";
      this.addProperty.possession = "";
      this.addProperty.pgfor = "";
      this.addProperty.occupency = "";
      this.addProperty.ptimes = "";
      this.addProperty.P_Age = "";
    },
    resetSalePG() {
      this.addProperty.ptype = "";
      this.addProperty.possession = "";
      this.addProperty.ptimes = "";
      this.addProperty.P_Age = "";
      this.addProperty.pgfor = "";
      this.addProperty.occupency = "";
    },
    resetRentSale() {
      this.addProperty.ptype = "";
      this.addProperty.possession = "";
      this.addProperty.ptimes = "";
      this.addProperty.P_Age = "";
      this.addProperty.picker = "";
    },
    resetRentPG() {
      this.addProperty.Available_from = "";
      this.addProperty.pg_for = "";
      this.addProperty.occupency = "";
    },
    resetNewProperty() {
      this.addProperty.possession = "";
      this.addProperty.ptimes = "";
      this.addProperty.P_Age = "";
    },
    resetResaleProperty() {
      this.addProperty.P_Age = "";
    },
    resetReadytoUse() {
      this.addProperty.P_Age = "";
    },
    resetUnderConstruction() {
      this.addProperty.ptimes = "";
    },
  },
};
