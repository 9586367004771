<template>
  <v-container>
    <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-stepper v-model="e1" vertical>
      <v-form
        @submit.prevent="updateProperty"
        ref="form"
        enctype="multipart/form-data"
        v-model="valid"
        lazy-validation
      >
        <v-stepper-step :complete="e1 > 1" step="1">
          Basic Info
        </v-stepper-step>
        <v-stepper-content step="1">
          <div v-for="addProperty in basicDetails" :key="addProperty.id">
            <div class="mb-5 pills">
              <div class="pills-group">
                <div class="font-weight-bold info--text">property For :</div>
                <span>
                  <input
                    type="radio"
                    name="property_for"
                    value="Sale"
                    id="sale"
                    v-model="addProperty.pfor"
                    @click="resetRentPG"
                    disabled
                  />
                  <label for="sale">Sale</label>
                </span>
                <span>
                  <input
                    type="radio"
                    name="property_for"
                    value="Rent"
                    id="rent_lease"
                    v-model="addProperty.pfor"
                    @click="resetSalePG"
                    disabled
                  />
                  <label for="rent_lease">Rent</label>
                </span>
                <!-- <span>
              <input type='radio' name='property_for' value='PG' id='pg' v-model="addProperty.pfor" @click="resetRentSale">
              <label for='pg'>PG</label>
            </span> -->
              </div>
            </div>
            <v-divider />
            <div class="mt-5 pills" v-if="addProperty.pfor === 'Rent'">
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :rules="PickerRules"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addProperty.Available_from"
                      label="Available From"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="PickerRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addProperty.Available_from"
                    @input="datepicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </div>
            <div class="mt-5 pills" v-if="addProperty.pfor === 'PG'">
              <div class="mb-5 pills-group" :rules="pgforRules">
                <div class="font-weight-bold info--text">
                  Accomodation For :
                </div>
                <span>
                  <input
                    type="radio"
                    name="type"
                    value="Male"
                    id="male"
                    v-model="addProperty.pgfor"
                  />
                  <label for="male">Male</label>
                </span>

                <span>
                  <input
                    type="radio"
                    name="type"
                    value="Female"
                    id="female"
                    v-model="addProperty.pgfor"
                  />
                  <label for="female">Female</label>
                </span>
              </div>

              <div class="mb-5 pills-group" :rules="occupencyRules">
                <div class="font-weight-bold info--text">Occupency:</div>
                <span>
                  <input
                    type="radio"
                    name="occupency"
                    value="single"
                    id="single"
                    v-model="addProperty.occupency"
                  />
                  <label for="single">single</label>
                </span>

                <span>
                  <input
                    type="radio"
                    name="occupency"
                    value="sharing"
                    id="sharing"
                    v-model="addProperty.occupency"
                  />
                  <label for="sharing">Sharing</label>
                </span>
              </div>
            </div>

            <div class="mt-5 pills" v-if="addProperty.pfor === 'Sale'">
              <div class="mb-5 pills-group" :rules="ptypeRules">
                <div class="font-weight-bold info--text">property type :</div>
                <span>
                  <input
                    type="radio"
                    name="type"
                    value="new property"
                    id="new"
                    disabled
                    v-model="addProperty.ptype"
                    @click="resetResaleProperty"
                  />
                  <label for="new">New Property</label>
                </span>

                <span>
                  <input
                    type="radio"
                    name="type"
                    value="resale property"
                    id="resale"
                    disabled
                    v-model="addProperty.ptype"
                    @click="resetNewProperty"
                  />
                  <label for="resale">Resale Property</label>
                </span>
              </div>
              <v-divider />
              <div
                class="mt-5 pills"
                v-if="addProperty.ptype === 'new property'"
              >
                <div class="mb-5 pills-group" :rules="possessionRules">
                  <div class="font-weight-bold info--text">Possession :</div>
                  <span>
                    <input
                      type="radio"
                      name="possession"
                      value="Under Construction"
                      id="underconstruction"
                      disabled
                      v-model="addProperty.possession"
                      @click="resetReadytoUse"
                    />
                    <label for="underconstruction">Under Construction</label>
                  </span>

                  <span>
                    <input
                      type="radio"
                      name="possession"
                      value="Ready to move"
                      id="readytomove"
                      disabled
                      v-model="addProperty.possession"
                      @click="resetUnderConstruction"
                    />
                    <label for="readytomove">Ready to move</label>
                  </span>
                </div>
                <v-divider />
                <div
                  class="mt-5 pills-group"
                  v-if="addProperty.possession === 'Under Construction'"
                >
                  <div>
                    <v-select
                      :items="ptime"
                      v-model="addProperty.p_time"
                      label="Possession"
                      value=""
                      :rules="ptimeRules"
                    />
                  </div>
                </div>
              </div>
              <div
                class="mt-5 pills-group"
                v-if="
                  addProperty.possession === 'Ready to move' ||
                  addProperty.ptype === 'resale property'
                "
              >
                <div>
                  <v-select
                    v-model="addProperty.p_age"
                    :items="age"
                    value=""
                    label="Property Age"
                    :rules="P_AgeRules"
                  />
                </div>
              </div>
            </div>
            <v-select
              v-model="addProperty.property_type_id"
              :items="propertylist"
              item-text="property_name"
              item-value="id"
              label="Select Property"
              :rules="propertyRules"
              readonly
            />
          </div>

          <v-btn small color="primary" @click="e1 = 2" class="float-right mt-5">
            Next
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e1 > 2" step="2">
          Address Details
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row v-for="addProperty in basicDetails" :key="addProperty.id">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="addProperty.locality"
                :rules="localityRules"
                label="Locality / Area"
                class="mb-4"
              />
              <v-textarea
                :rules="addressRules"
                label="Enter your Address"
                class="mb-4"
                v-model="basicDetails.address"
              />
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-text-field
                ref="city"
                :value="addProperty.city"
                :rules="cityRules"
                label="Enter your City"
                readonly
                class="mb-4"
              />

              <v-select
                :items="states"
                item-text="state_name"
                item-value="id"
                :rules="stateRules"
                label="Select State"
                readonly
                v-model="addProperty.state"
              />

              <v-select
                :items="cities"
                v-model="addProperty.district"
                item-text="city_name"
                item-value="id"
                label="Select District"
                :rules="districtRules"
                readonly
                @click="getCityList(addProperty.state)"
              />
            </v-col>
          </v-row>
          <v-btn small color="primary" @click="e1 = 1" class="float-left mt-5">
            Previous
          </v-btn>

          <v-btn small color="primary" @click="e1 = 3" class="float-right mt-5">
            Next
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e1 > 3" step="3">
          Property Details
        </v-stepper-step>

        <v-stepper-content step="3">
          <div class="titlebar">
            <div class="font-weight-bold white--text">
              <div v-for="addProperty in basicDetails" :key="addProperty.id">
                {{ addProperty.propertytype.property_name }}
              </div>
            </div>
          </div>

          <v-row>
            <div v-for="addProperty in basicDetails" :key="addProperty.id">
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-text-field
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                  v-model="addProperty.super_built_area"
                  :rules="sbuiltupRules"
                  label="Super Builtup Area (in Sq.ft)"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 7 ||
                    addProperty.property_type_id == 8
                  "
                  v-model="addProperty.hectare"
                  :rules="hareaRules"
                  label="Area In Hectre"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 16
                  "
                  v-model="addProperty.built_area"
                  :rules="builtupRules"
                  label="Builtup Area (in Sq.ft)"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 16
                  "
                  v-model="addProperty.carpet_area"
                  :rules="carpetRules"
                  label="Carpet Area (in Sq.ft)"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 16 ||
                    addProperty.property_type_id == 17
                  "
                  v-model="addProperty.plot_area"
                  :rules="plotRules"
                  label="Plot Area (in Sq.ft)"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 16 ||
                    addProperty.property_type_id == 17
                  "
                  v-model="addProperty.p_width"
                  :rules="pwidthRules"
                  label="Plot Width (in ft.)"
                  type="text"
                  class=""
                />

                <v-text-field
                  v-if="
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 16 ||
                    addProperty.property_type_id == 17
                  "
                  v-model="addProperty.p_length"
                  :rules="plengthRules"
                  label="Plot Length (in ft.)"
                  type="text"
                  class=""
                />

                <v-select
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 16 ||
                    addProperty.property_type_id == 17
                  "
                  v-model="addProperty.facing"
                  :items="facinglist"
                  label="Facing"
                  :rules="facingRules"
                />
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6">
                <v-select
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                  v-model="addProperty.total_no_floor"
                  :items="itemlist"
                  :rules="totalfloorRules"
                  label="Total no. of floor"
                />
                {{ addProperty.total_no_floor }}
                <v-select
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                  v-model="addProperty.floor_no"
                  :rules="floorRules"
                  label="Floor No."
                  :items="itemlist"
                />
                <div
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8
                  "
                >
                  <v-select
                    v-model="addProperty.bedroom"
                    :items="itemlist"
                    :rules="bedroomRules"
                    label="No. of Bedroom"
                  />
                </div>

                <v-select
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8
                  "
                  v-model="addProperty.bathroom"
                  :items="itemlist"
                  label="No. of Bathroom"
                  :rules="bathroomRules"
                />

                <v-select
                  v-if="
                    addProperty.property_type_id == 1 ||
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 6 ||
                    addProperty.property_type_id == 8
                  "
                  v-model="addProperty.balcony"
                  :items="balconylist"
                  label="No. Of Balcony"
                  :rules="balconyRules"
                />
                <v-text-field
                  v-if="
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 17
                  "
                  v-model="addProperty.road_width"
                  :rules="roadwidthRules"
                  label="Road width in front of plot(in ft.)"
                  type="text"
                  class=""
                />

                <v-select
                  v-if="
                    addProperty.property_type_id == 2 ||
                    addProperty.property_type_id == 3 ||
                    addProperty.property_type_id == 4 ||
                    addProperty.property_type_id == 5 ||
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 14 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 16 ||
                    addProperty.property_type_id == 17
                  "
                  :items="sides"
                  label="No. of open sides"
                  :rules="opensideRules"
                  v-model="addProperty.open_side"
                />
                <div
                  class="pills-group"
                  v-if="
                    addProperty.property_type_id == 13 ||
                    addProperty.property_type_id == 15 ||
                    addProperty.property_type_id == 17
                  "
                >
                  <div class="font-weight-bold info--text">
                    Compound on Plot :
                  </div>
                  <span>
                    <input
                      type="radio"
                      name="compound"
                      value="1"
                      id="compound-yes"
                      v-model="addProperty.compound"
                    />
                    <label for="compound-yes">Yes</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      name="compound"
                      value="0"
                      id="compound-no"
                      v-model="addProperty.compound"
                    />
                    <label for="compound-no">No</label>
                  </span>
                </div>
                <v-select
                  v-if="
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                  :items="items"
                  :rules="washroomRules"
                  label="No. of Washroom"
                  v-model="addProperty.washroom"
                />
                <div
                  class="mb-3 pills-group"
                  v-if="
                    addProperty.property_type_id == 9 ||
                    addProperty.property_type_id == 10 ||
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                >
                  <div
                    class="font-weight-bold info--text"
                    :rules="cafeteriaRules"
                  >
                    Pantry or Cafeteria :
                  </div>
                  <span>
                    <input
                      type="radio"
                      name="pantry"
                      value="1"
                      id="pantry-yes"
                      v-model="addProperty.cafeteria"
                    />
                    <label for="pantry-yes">Yes</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      name="pantry"
                      value="0"
                      id="pantry-no"
                      v-model="addProperty.cafeteria"
                    />
                    <label for="pantry-no">No</label>
                  </span>
                </div>

                <div
                  class="mb-3 pills-group"
                  v-if="
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                >
                  <div
                    class="font-weight-bold info--text"
                    :rules="cornerPropertyRules"
                  >
                    Corner Property:
                  </div>
                  <span>
                    <input
                      type="radio"
                      name="corner_property"
                      value="1"
                      id="corner-yes"
                      v-model="addProperty.corner_property"
                    />
                    <label for="corner-yes">Yes</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      name="corner_property"
                      value="0"
                      id="corner-no"
                      v-model="addProperty.corner_property"
                    />
                    <label for="corner-no">No</label>
                  </span>
                </div>

                <div
                  class="mb-3 pills-group"
                  v-if="
                    addProperty.property_type_id == 11 ||
                    addProperty.property_type_id == 12
                  "
                >
                  <div
                    class="font-weight-bold info--text"
                    :rules="mainRoadFacingRules"
                  >
                    Main Road Facing:
                  </div>
                  <span>
                    <input
                      type="radio"
                      name="road_facing"
                      value="1"
                      id="mainroad-yes"
                      v-model="addProperty.road_facing"
                    />
                    <label for="mainroad-yes">Yes</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      name="road_facing"
                      value="0"
                      id="mainroad-no"
                      v-model="addProperty.road_facing"
                    />
                    <label for="mainroad-no">No</label>
                  </span>
                </div>
              </v-col>
            </div>
          </v-row>
          <div v-for="addProperty in basicDetails" :key="addProperty.id">
            <v-text-field
              v-model="addProperty.price"
              :rules="priceRules"
              label="Amount in Rupees "
              type="text"
              class="mb-4"
            />
            <div class="pills-group">
              <div class="font-weight-bold info--text">price Negotiable :</div>
              <span>
                <input
                  type="radio"
                  name="price_negotiation"
                  value="1"
                  id="price-yes"
                  v-model="addProperty.price_negotiable"
                />
                <label for="price-yes">Yes</label>
              </span>
              <span>
                <input
                  type="radio"
                  name="price_negotiation"
                  value="0"
                  id="price-no"
                  v-model="addProperty.price_negotiable"
                />
                <label for="price-no">No</label>
              </span>
            </div>
            <v-textarea
              v-model="addProperty.description"
              :rules="descriptionRules"
              label="Description"
              type="text"
              class="mb-4"
            />
            <v-btn
              color="primary"
              @click="e1 = 2"
              class="float-left mt-5"
              small
            >
              PREVIOUS
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              :disabled="!valid"
              class="float-right mt-5"
              @click="validate"
              small
            >
              SAVE
            </v-btn>

            <v-btn to="/user/home" class="float-right mt-5" small>
              Cancel
            </v-btn>
          </div>
        </v-stepper-content>
      </v-form>
    </v-stepper>
    <v-row class="mt-4">
      <div>
        <span> amenities</span>
        <v-row
          v-if="
            addProperty.property_type_id == 1 ||
            addProperty.property_type_id == 6 ||
            addProperty.property_type_id == 9 ||
            addProperty.property_type_id == 10 ||
            addProperty.property_type_id == 11 ||
            addProperty.property_type_id == 12
          "
        >
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-select
              v-model="addProperty.maintenance"
              :items="maintenanceamenities"
              item-text="amenity_name"
              item-value="id"
              label="Select Maintenence"
              :rules="maintenanceRules"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="
            addProperty.property_type_id == 1 ||
            addProperty.property_type_id == 2 ||
            addProperty.property_type_id == 3 ||
            addProperty.property_type_id == 4 ||
            addProperty.property_type_id == 5 ||
            addProperty.property_type_id == 6
          "
        >
          <v-col v-for="wateramenity in wateramenities" :key="wateramenity.id">
            <v-checkbox
              :value="wateramenity.id"
              :label="wateramenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col
            v-for="kitchenamenity in kitchenamenities"
            :key="kitchenamenity.id"
          >
            <v-checkbox
              :value="kitchenamenity.id"
              :label="kitchenamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col
            v-for="flooringamenity in flooringamenities"
            :key="flooringamenity.id"
          >
            <v-checkbox
              :value="flooringamenity.id"
              :label="flooringamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col v-for="popamenity in popamenities" :key="popamenity.id">
            <v-checkbox
              :value="popamenity.id"
              :label="popamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="addProperty.property == 1 || addProperty.property == 6">
          <v-col
            v-for="parkingamenity in parkingamenities"
            :key="parkingamenity.id"
          >
            <v-checkbox
              :value="parkingamenity.id"
              :label="parkingamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col
            v-for="furnishedamenity in furnishedamenities"
            :key="furnishedamenity.id"
          >
            <v-checkbox
              :value="furnishedamenity.id"
              :label="furnishedamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col
            v-for="safetyamenity in safetyamenities"
            :key="safetyamenity.id"
          >
            <v-checkbox
              :value="safetyamenity.id"
              :label="safetyamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col v-for="basicamenity in basicamenities" :key="basicamenity.id">
            <v-checkbox
              :value="basicamenity.id"
              :label="basicamenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="
            addProperty.property == 1 ||
            addProperty.property == 2 ||
            addProperty.property == 3 ||
            addProperty.property == 4 ||
            addProperty.property == 5 ||
            addProperty.property == 6
          "
        >
          <v-col v-for="otheramenity in otheramenities" :key="otheramenity.id">
            <v-checkbox
              :value="otheramenity.id"
              :label="otheramenity.amenity_name"
              v-model="addProperty.amenity"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          @submit.prevent="updateImage"
          ref="form"
          enctype="multipart/form-data"
          v-model="validImg"
          lazy-validation
        >
          <div>
            <v-row>
              <div v-for="(image, key) in images" :key="image.id">
                <v-col cols="6">
                  <div class="preview-img">
                    <img v-bind:ref="'image' + parseInt(key)" width="120px" />
                    <v-btn
                      color="grey"
                      icon
                      @click="removeImage(image, key)"
                      class="remove-image"
                    >
                      <v-icon> mdi-close-circle </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-row>
            <v-row
              ><span class="info--text mt-3 ml-5">
                You can upload multiple images.(upto 10 images) at a time.
              </span>
            </v-row>
            <v-file-input
              type="file"
              :error-messages="errmsg"
              show-size
              multiple
              accept="image/png,image/jpeg"
              prepend-icon="mdi-camera"
              label="Upload property image"
              @change="uploadImage"
            />
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import EditPropertyMixin from "../../mixins/EditPropertyMixin";
import Property from "../../api/Property";
//import PhotoUpload from '../../components/PhotoUpload.vue'
export default {
  name: "Edit-Property",
  props: ["id"],
  mixins: [EditPropertyMixin],
  data() {
    return {
      address: "",
      snackbar: false,
      text: "",
      snackbar_color: null,
      propPicture: "",
      userphoto: "",
      e1: 1,
      valid: true,
      validImg: true,
      errmsg: "",
      propertyImg: null,
      selectedFile: "",
      images: [],
      imgURL: "https://100milesrealtors.com/routes/storage/images",
    };
  },

  computed: {
    ...mapState({
      propertylist: (state) => state.property.propertylist,
      states: (state) => state.city.states,
      cities: (state) => state.city.cities,
      basicamenities: (state) => state.amenity.basicamenities,
      safetyamenities: (state) => state.amenity.safetyamenities,
      parkingamenities: (state) => state.amenity.parkingamenities,
      wateramenities: (state) => state.amenity.wateramenities,
      flooringamenities: (state) => state.amenity.flooringamenities,
      popamenities: (state) => state.amenity.popamenities,
      kitchenamenities: (state) => state.amenity.kitchenamenities,
      furnishedamenities: (state) => state.amenity.furnishedamenities,
      maintenanceamenities: (state) => state.amenity.maintenanceamenities,
      otheramenities: (state) => state.amenity.otheramenities,
      basicDetails: (state) => state.property.basicDetails,
      propertyPhoto: (state) => state.property.propertyPhoto,
      amenityDetails: (state) => state.property.amenityDetails,
    }),
  },

  mounted() {
    this.getProperty();
    this.getState();
    this.otherAmenity();
    this.safetyAmenity();
    this.parkingAmenity();
    this.waterAmenity();
    this.flooringAmenity();
    this.kitchenAmenity();
    this.basicAmenity();
    this.popAmenity();
    this.furnishedAmenity();
    this.maintenanceAmenity();
    this.showPropertyDetails();
  },
  methods: {
    ...mapActions([
      "getProperty",
      "getState",
      "getCity",
      "otherAmenity",
      "safetyAmenity",
      "parkingAmenity",
      "waterAmenity",
      "flooringAmenity",
      "kitchenAmenity",
      "basicAmenity",
      "popAmenity",
      "furnishedAmenity",
      "maintenanceAmenity",
      "updateProperty",
      "viewPropertyDetails",
    ]),
    showPropertyDetails() {
      this.viewPropertyDetails({
        propertyId: this.id,
      });
    },
    validate() {
      this.$refs.form.validate("getState");
      //this.$refs.form.validate('getCity');
    },
    getCityList(state) {
      console.log(state.id);
      this.getCity({
        cityId: state.id,
      });
    },
    imageSize() {
      const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
      var totalSize = 0;
      for (let i = 0; i < this.images.length; i++) {
        //console.log("this.images[i].size: ",this.images[i].size);
        totalSize = totalSize + this.images[i].size;
      }
      let imgSize = bytesToMegaBytes(totalSize);
      //console.log(imgSize,"MB");
      var roundSize = Math.round(imgSize);
      if (roundSize > 10) {
        //console.log("roundSize :",roundSize,"MB");
        this.errmsg = " File size should be less than 10 MB!";
      }
    },
    uploadImage(e) {
      var selectedFiles = e;
      console.log(e);
      if (e.length !== 0) {
        if (e.length <= 10 && this.images.length <= 10) {
          this.errmsg = "";
          for (let i = 0; i < selectedFiles.length; i++) {
            this.images.push(selectedFiles[i]);
          }
          this.imageSize();
          this.previewImg();
        } else {
          this.errmsg = "Upload Only 10 Images.";
        }
      } else {
        this.images.length = [];
        this.previewImg();
        this.errmsg = "Please select upto 10 images.";
      }
    },
    removeImage(image, index) {
      this.images.splice(index, 1);
      //console.log(this.images);
      this.imageSize();
      this.previewImg();
    },
    previewImg() {
      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener(
          "load",
          function () {
            this.$refs["image" + parseInt(i)][0].src = reader.result;
          }.bind(this),
          false
        ); //add event listener
        reader.readAsDataURL(this.images[i]);
      }
    },
    async updateProperty() {
      const fd = new FormData();
      fd.append("pfor", this.addProperty.pfor);
      fd.append("ptype", this.addProperty.ptype);
      fd.append("possession", this.addProperty.possession);
      fd.append("pgfor", this.addProperty.pg_for);
      fd.append("occupency", this.addProperty.occupency);
      fd.append("p_time", this.addProperty.p_time);
      fd.append("P_Age", this.addProperty.P_Age);
      fd.append("picker", this.addProperty.Available_from);
      fd.append("property", this.addProperty.property_type_id);
      fd.append("locality", this.addProperty.locality);
      fd.append("address", this.addProperty.address);
      fd.append("city", this.addProperty.city);
      fd.append("district", this.addProperty.district);
      fd.append("state", this.addProperty.state);
      fd.append("negotiable", this.addProperty.price_negotiable);
      fd.append("description", this.addProperty.description);
      fd.append("price", this.addProperty.price);
      fd.append("sbuiltup", this.addProperty.super_built_area);
      fd.append("builtup", this.addProperty.built_area);
      fd.append("carpet", this.addProperty.carpet_area);
      fd.append("harea", this.addProperty.hectare);
      fd.append("plotarea", this.addProperty.plot_area);
      fd.append("totalfloor", this.addProperty.total_no_floorfloor);
      fd.append("floorno", this.addProperty.floor_noorno);
      fd.append("bedroom", this.addProperty.bedroom);
      fd.append("bathroom", this.addProperty.bathroom);
      fd.append("balcony", this.addProperty.balcony);
      fd.append("facing", this.addProperty.facing);
      fd.append("pwidth", this.addProperty.p_widthth);
      fd.append("plength", this.addProperty.p_lengthgth);
      fd.append("roadwidth", this.addProperty.road_width);
      fd.append("open_side", this.addProperty.open_side);
      fd.append("compound", this.addProperty.compound);
      fd.append("cafeteria", this.addProperty.cafeteria);
      fd.append("washroom", this.addProperty.washroom);
      fd.append("road_facing", this.addProperty.road_facing);
      fd.append("corner_property", this.addProperty.corner_property);
      fd.append("maintenance", this.addProperty.maintenance);
      console.log("addProperty : ", this.addProperty);
      fd.forEach((value, key) => {
        console.log(key + " : " + value);
      });
      // Property.updateProperty(this.id, fd)
      //   .then((response) => {
      //     if (response.status == 201) {
      //       this.text = response.data.message;
      //       this.snackbar = true;
      //       this.snackbar_color = "success";
      //       console.log(response.data);
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log("Error message :", error);
      //     this.text = error.response.data.errors;
      //     this.snackbar = true;
      //     this.snackbar_color = "error";
      //   });
    },
    updateImage() {
      if (this.images.length > 0 && this.images.length <= 10) {
        Property.updatePropertyImage(this.basicDetails.basic_property_id)
          .then((response) => {
            if (response.status == 201) {
              this.text = response.data.message;
              this.snackbar = true;
              this.snackbar_color = "success";
              console.log(response.data);
            }
          })
          .catch(function (error) {
            console.log("Error message :", error.message);
            this.text = error.response.data.errors;
            this.snackbar = true;
            this.snackbar_color = "error";
          });
      } else {
        if (this.images.length == 0) {
          this.text = "please select Images";
          this.snackbar = true;
          this.snackbar_color = "error";
        }
        if (this.images.length > 10) {
          this.text = "Please upload upto 10 images";
          this.snackbar = true;
          this.snackbar_color = "error";
        }
      }
    },
  },
};
</script>

<style>
input[type="radio"] {
  display: none;
}
.pills-group {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.pills-group > span {
  margin-left: 10px;
  display: block;
  text-align: center;
}
.pills-group > span > label {
  flex: 1 1 auto;
  border: solid 1px #e0e0e0;
  min-width: 80px;
  cursor: pointer;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50px;
  background-color: #fafafa;
}
.pills-group > span > :checked + label {
  background: #1976d2;
  color: #fff;
}

.titlebar {
  width: 100%;
  padding: 15px 10px;
  height: 50px;
  background: #1976d2;
  border-radius: 5px 5px 0 0;
}
.preview-img {
  display: inline-block;
  position: relative;
  width: auto;
  margin: 2px 1px 2px 1px;
}
.preview-img img {
  width: 200px;
  border-radius: 5px 5px 5px 5px;
  border: solid 1.5px rgb(197, 197, 197);
}
.preview-img .remove-image {
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 2px;
}
</style>
